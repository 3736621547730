























































































import { defineComponent, computed, ref, Ref, watch, onMounted, onUnmounted } from '@vue/composition-api';
import { CircleSpinner, HexagonIcon } from '@nimiq/vue-components';
import { AddressBook } from '@nimiq/utils';
import TransactionListItem from '@/components/TransactionListItem.vue';
import TestnetFaucet from './TestnetFaucet.vue';
import CrossCloseButton from './CrossCloseButton.vue';
import { useAddressStore } from '../stores/Address';
import { Transaction, TransactionState } from '../stores/Transactions';
import { useContactsStore } from '../stores/Contacts';
import { useNetworkStore } from '../stores/Network';
import { parseData } from '../lib/DataFormatting';
import { ENV_MAIN } from '../lib/Constants';
import { isProxyData, ProxyType, ProxyTransactionDirection } from '../lib/ProxyDetection';
import { createCashlink } from '../hub';
import { useConfig } from '../composables/useConfig';
import { useWindowSize } from '../composables/useWindowSize';
import { useTransactionInfo } from '../composables/useTransactionInfo';

function processTimestamp(timestamp: number) {
    const date: Date = new Date(timestamp);

    return {
        month: date.getMonth(),
        year: date.getFullYear(),
        date,
    };
}

function getLocaleMonthStringFromDate(
    date: Date,
    locale: string,
    options: {
        month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow',
        year?: 'numeric' | '2-digit',
    },
) {
    return new Intl.DateTimeFormat(locale, options).format(date);
}

// function getCloserElement(element: any, classToFind: string): HTMLElement {
//     let e = element as HTMLElement;
//
//     if (!e) throw new Error('element undefined');
//
//     const selector = `.${classToFind}`;
//
//     if (e.matches(selector)) return e;
//
//     const child = e.querySelector(`.${classToFind}`) as HTMLElement;
//     if (child) return child;
//
//     while (e && !e.matches(selector)) {
//         e = e.parentNode as HTMLElement;
//     }
//     return e;
// }

export default defineComponent({
    props: {
        searchString: {
            type: String,
            default: '',
        },
        showUnclaimedCashlinkList: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, context) {
        const { activeAddress, state: addresses$, activeAddressInfo, transactionsForActiveAddress } = useAddressStore();
        const { isFetchingTxHistory } = useNetworkStore();
        const { getLabel: getContactLabel } = useContactsStore();
        const { config } = useConfig();

        // Amount of pixel to add to edges of the scrolling visible area to start rendering items further away
        const scrollerBuffer = 300;

        // Height of items in pixel
        const { isMobile } = useWindowSize();
        const itemSize = computed(() => isMobile.value ? 68 : 72); // mobile: 64px + 4px margin between items

        const txCount = computed(() => transactionsForActiveAddress.value.length);

        const unclaimedCashlinkTxs = computed(() => transactionsForActiveAddress.value.filter(
            (tx) => tx.sender === activeAddress.value && !tx.relatedTransactionHash
                && isProxyData(tx.data.raw, ProxyType.CASHLINK, ProxyTransactionDirection.FUND),
        ));

        // Count unclaimed cashlinks
        watch(() => {
            const count = unclaimedCashlinkTxs.value.length;
            context.emit('unclaimed-cashlink-count', count);
        });

        // Apply search filter
        const filteredTxs = computed(() => {
            if (!props.searchString) return transactionsForActiveAddress.value;

            const searchStrings = props.searchString.toUpperCase().split(' ').filter((value) => value !== '');

            return transactionsForActiveAddress.value.filter((tx) => {
                const transaction = ref<Readonly<Transaction>>(tx);
                const { peerLabel, data } = useTransactionInfo(transaction);

                const senderLabel = addresses$.addressInfos[tx.sender]
                    ? addresses$.addressInfos[tx.sender].label
                    : getContactLabel.value(tx.sender) || AddressBook.getLabel(tx.sender) || '';

                const recipientLabel = addresses$.addressInfos[tx.recipient]
                    ? addresses$.addressInfos[tx.recipient].label
                    : getContactLabel.value(tx.recipient) || AddressBook.getLabel(tx.recipient) || '';

                const concatenatedTxStrings = `
                    ${tx.sender.replace(/\s/g, '')}
                    ${tx.recipient.replace(/\s/g, '')}
                    ${peerLabel.value ? (peerLabel.value as string).toUpperCase() : ''}
                    ${senderLabel ? senderLabel.toUpperCase() : ''}
                    ${recipientLabel ? recipientLabel.toUpperCase() : ''}
                    ${data.value.toUpperCase()}
                    ${parseData(tx.data.raw).toUpperCase()}
                `;
                return searchStrings.every((searchString) => concatenatedTxStrings.includes(searchString));
            });
        });

        const transactions = computed(() => {
            // Display loading transactions
            if (!filteredTxs.value.length && isFetchingTxHistory.value) {
                // create just as many placeholders that the scroller doesn't start recycling them because the loading
                // animation breaks for recycled entries due to the animation delay being off.
                const listHeight = window.innerHeight - 220; // approximated to avoid enforced layouting by offsetHeight
                const placeholderCount = Math.floor((listHeight + scrollerBuffer) / itemSize.value);
                return [...new Array(placeholderCount)].map((e, i) => ({ transactionHash: i, loading: true }));
            }

            if (!filteredTxs.value.length) return [];

            const txs = filteredTxs.value;

            // Inject "This month" label
            const transactionsWithMonths: any[] = [];
            let isLatestMonth = true;

            const { month: currentMonth, year: currentYear } = processTimestamp(Date.now());
            let n = 0;
            let hasThisMonthLabel = false;

            if (txs[n].state === TransactionState.PENDING) {
                transactionsWithMonths.push({ transactionHash: context.root.$t('This month'), isLatestMonth });
                isLatestMonth = false;
                hasThisMonthLabel = true;
                while (txs[n] && txs[n].state === TransactionState.PENDING) {
                    transactionsWithMonths.push(txs[n]);
                    n++;
                }
            }

            // Skip expired & invalidated txs
            while (txs[n] && !txs[n].timestamp) {
                transactionsWithMonths.push(txs[n]);
                n++;
            }

            if (!txs[n]) return transactionsWithMonths; // Address has no more txs

            // Inject month + year labels
            let { month: txMonth, year: txYear } = processTimestamp(txs[n].timestamp! * 1000);
            let txDate: Date;

            if (!hasThisMonthLabel && txMonth === currentMonth && txYear === currentYear) {
                transactionsWithMonths.push({ transactionHash: context.root.$t('This month'), isLatestMonth });
                isLatestMonth = false;
            }

            let displayedMonthYear = `${currentMonth}.${currentYear}`;

            while (n < txs.length) {
                // Skip expired & invalidated txs
                if (!txs[n].timestamp) {
                    transactionsWithMonths.push(txs[n]);
                    n++;
                    continue;
                }

                ({ month: txMonth, year: txYear, date: txDate } = processTimestamp(txs[n].timestamp! * 1000));
                const txMonthYear = `${txMonth}.${txYear}`;

                if (txMonthYear !== displayedMonthYear) {
                    // Inject a month label
                    transactionsWithMonths.push({
                        transactionHash: getLocaleMonthStringFromDate(
                            txDate,
                            context.root.$i18n.locale,
                            {
                                month: 'long',
                                year: txYear !== currentYear ? 'numeric' : undefined,
                            },
                        ),
                        isLatestMonth,
                    });
                    isLatestMonth = false;
                    displayedMonthYear = txMonthYear;
                }

                transactionsWithMonths.push(txs[n]);
                n++;
            }

            return transactionsWithMonths;
        });

        // listening for DOM changes for animations in the virtual scroll
        // TODO reconsider whether we actually want to have this animation. If so, fix it such that the animation
        // only runs on transaction hash change.
        const root: Ref<null | HTMLElement> = ref(null);
        // (() => {
        //     let txHashList = transactions.value.map((tx: Transaction) => tx.transactionHash + activeAddress.value);
        //     const config = { characterData: true, childList: true, subtree: true };
        //     const callback = async function mutationCallback(mutationsList: MutationRecord[]) {
        //         if (!transactions.value.length) return;
        //         const changedIndexes: string[] = [];
        //
        //         for (const mutation of mutationsList) {
        //             let element: null | HTMLElement = null;
        //
        //             if (mutation.target) {
        //                 if (
        //                     mutation.type === 'childList'
        //                     && !(mutation.target as HTMLElement).classList.contains('transaction-list')
        //                     && !(mutation.target as HTMLElement).classList.contains('resize-observer')
        //                 ) {
        //                     element = getCloserElement(mutation.target, 'list-element');
        //                 } else if (
        //                     mutation.type === 'characterData'
        //                     && mutation.target.parentNode
        //                 ) {
        //                     element = getCloserElement(mutation.target.parentNode, 'list-element');
        //                 }
        //             }
        //
        //             if (element && !changedIndexes.includes(element.dataset.id!)) {
        //                 changedIndexes.push(element.dataset.id!);
        //
        //                 const changedTxHash = element.dataset.hash as string;
        //
        //                 if (!txHashList.includes(changedTxHash + activeAddress.value)) { // added element
        //                     txHashList.push(changedTxHash + activeAddress.value);
        //                     element.classList.remove('fadein');
        //                     requestAnimationFrame(() => element!.classList.add('fadein'));
        //                 }
        //             }
        //         }
        //
        //         txHashList = transactions.value.map((tx: Transaction) => tx.transactionHash + activeAddress.value);
        //     };
        //
        //     const observer = new MutationObserver(callback);
        //
        //     onMounted(() => observer.observe(root.value!, config));
        //     onBeforeUnmount(() => observer.disconnect());
        // })();

        // Does not need to be reactive, as the environment doesn't change during runtime.
        const isMainnet = config.environment === ENV_MAIN;

        function onCreateCashlink() {
            createCashlink(activeAddress.value!, activeAddressInfo.value!.balance || undefined);
        }

        // Scroll to top when
        // - Active address changes
        // - Unclaimed Cashlinks list is opened
        const scroller = ref<{
            scrollToPosition(position: number, smooth?: boolean): void,
            $el: HTMLDivElement,
                } | null>(null);

        watch(activeAddress, () => {
            if (scroller.value) {
                scroller.value.scrollToPosition(0, false); // No smooth scrolling on address change
            }
        });

        watch(() => props.showUnclaimedCashlinkList, (show) => {
            if (show && scroller.value) {
                scroller.value.scrollToPosition(0, true);
            }
        });

        function onScroll() {
            context.emit('scroll');
        }

        // @scroll / @scroll.native doesn't seem to work, so using standard event system
        onMounted(() => {
            if (!scroller.value) return;
            scroller.value.$el.addEventListener('scroll', onScroll);
        });

        onUnmounted(() => {
            if (!scroller.value) return;
            scroller.value.$el.removeEventListener('scroll', onScroll);
        });

        return {
            activeAddress,
            scrollerBuffer,
            itemSize,
            txCount,
            transactions,
            root,
            isFetchingTxHistory,
            isMainnet,
            unclaimedCashlinkTxs,
            onCreateCashlink,
            scroller,
        };
    },
    components: {
        TransactionListItem,
        TestnetFaucet,
        CrossCloseButton,
        CircleSpinner,
        HexagonIcon,
    },
});
