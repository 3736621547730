

























import { defineComponent, ref, computed, onMounted, onUnmounted, watch } from '@vue/composition-api';
import { FiatAmount } from '@nimiq/vue-components';
import PrivacyOffIcon from './icons/PrivacyOffIcon.vue';
import PrivacyOnIcon from './icons/PrivacyOnIcon.vue';
import { useAddressStore } from '../stores/Address';
import { useBtcAddressStore } from '../stores/BtcAddress';
import { usePolygonAddressStore } from '../stores/PolygonAddress';
import { useSettingsStore } from '../stores/Settings';
import { useFiatStore } from '../stores/Fiat';
import { useConfig } from '../composables/useConfig';
import { useWindowSize } from '../composables/useWindowSize';
import { CryptoCurrency } from '../lib/Constants';
import { useAccountSettingsStore } from '../stores/AccountSettings';
import { usePrestakingStore } from '../stores/Prestaking';

export default defineComponent({
    setup(props, context) {
        const { accountBalance } = useAddressStore();
        const { accountPrestake } = usePrestakingStore();
        const { accountBalance: btcAccountBalance } = useBtcAddressStore();
        const {
            accountUsdcBalance,
            accountUsdcBridgedBalance,
            accountUsdtBridgedBalance,
        } = usePolygonAddressStore();
        const { stablecoin } = useAccountSettingsStore();
        const { currency: fiatCurrency, exchangeRates } = useFiatStore();
        const { config } = useConfig();

        const fiatAmount = computed(() => {
            let amount = 0;

            const nimExchangeRate = exchangeRates.value[CryptoCurrency.NIM]?.[fiatCurrency.value];
            const nimFiatAmount = nimExchangeRate !== undefined
                ? ((accountBalance.value + accountPrestake.value) / 1e5) * nimExchangeRate
                : undefined;
            if (nimFiatAmount === undefined) return undefined;
            amount += nimFiatAmount;

            if (config.enableBitcoin) {
                const btcExchangeRate = exchangeRates.value[CryptoCurrency.BTC]?.[fiatCurrency.value];
                const btcFiatAmount = btcExchangeRate !== undefined
                    ? (btcAccountBalance.value / 1e8) * btcExchangeRate
                    : undefined;
                if (btcFiatAmount === undefined) return undefined;
                amount += btcFiatAmount;
            }

            if (config.polygon.enabled) {
                const usdcExchangeRate = exchangeRates.value[CryptoCurrency.USDC]?.[fiatCurrency.value];
                const usdtExchangeRate = exchangeRates.value[CryptoCurrency.USDT]?.[fiatCurrency.value];
                const usdFiatAmount = {
                    [CryptoCurrency.USDC]: usdcExchangeRate !== undefined
                        ? ((accountUsdcBalance.value + accountUsdcBridgedBalance.value) / 1e6) * usdcExchangeRate
                        : undefined,
                    [CryptoCurrency.USDT]: usdtExchangeRate !== undefined
                        ? (accountUsdtBridgedBalance.value / 1e6) * usdtExchangeRate
                        : undefined,
                    none: 0,
                }[stablecoin.value || 'none'];
                if (usdFiatAmount === undefined) return undefined;
                amount += usdFiatAmount;
            }

            return amount;
        });

        const fiatAmountContainer$ = ref<HTMLDivElement>(null);
        const fiatAmount$ = ref<FiatAmount>(null);

        const { isFullDesktop } = useWindowSize();
        const fiatAmountMaxSize = computed(() => isFullDesktop.value ? 7 : 5.5); // rem
        const fiatAmountFontSize = ref(fiatAmountMaxSize.value);

        async function updateFontSize() {
            await context.root.$nextTick();
            if (!fiatAmount$.value) return;

            if (!fiatAmountContainer$.value || !fiatAmount$.value) return;

            const availableWidth = fiatAmountContainer$.value!.offsetWidth;
            const referenceWidth = (fiatAmount$.value!.$el as HTMLElement).offsetWidth;
            const scaleFactor = Math.round((availableWidth / referenceWidth) * 100) / 100;

            if (scaleFactor > 1.02 || scaleFactor < 0.98) { // needed for safari
                fiatAmountFontSize.value = Math.min(fiatAmountMaxSize.value, fiatAmountFontSize.value * scaleFactor);
            }
        }

        onMounted(() => {
            window.addEventListener('resize', updateFontSize);
        });
        onUnmounted(() => window.removeEventListener('resize', updateFontSize));

        watch(fiatAmount$, () => {
            if (!fiatAmount$.value) updateFontSize();
        }, { lazy: true });

        const { amountsHidden, toggleAmountsHidden } = useSettingsStore();

        return {
            fiatAmount,
            fiatCurrency,
            fiatAmount$,
            fiatAmountContainer$,
            fiatAmountFontSize,
            amountsHidden,
            toggleAmountsHidden,
        };
    },
    components: {
        FiatAmount,
        PrivacyOffIcon,
        PrivacyOnIcon,
    },
});
