import { render, staticRenderFns } from "./FiatConvertedAmount.vue?vue&type=template&id=679b5990&scoped=true&"
import script from "./FiatConvertedAmount.vue?vue&type=script&lang=ts&"
export * from "./FiatConvertedAmount.vue?vue&type=script&lang=ts&"
import style0 from "./FiatConvertedAmount.vue?vue&type=style&index=0&id=679b5990&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "679b5990",
  null
  
)

export default component.exports