




















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    setup() {
        return {
        };
    },
    props: {
        unclaimedCashlinkCount: {
            type: Number,
        },
        showUnclaimedCashlinkList: {
            type: Boolean,
            default: false,
        },
    },
    components: {
    },
});
