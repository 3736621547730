


























import { defineComponent, ref, watch } from '@vue/composition-api';

export default defineComponent({
    setup(props, context) {
        const preventNextTransition = ref(false);

        watch(() => context.root.$route, (to) => {
            preventNextTransition.value = to.name === 'network';
        }, { lazy: true });

        return {
            preventNextTransition,
        };
    },
});
