







import { defineComponent, computed } from '@vue/composition-api';
import { getBackgroundClass } from '../lib/AddressColor';

export default defineComponent({
    props: {
        label: {
            type: String,
            required: false,
        },
    },
    setup(props) {
        const unlabelled = computed(() => !props.label);
        const initial = computed(() => props.label?.substr(0, 1));
        const backgroundColor = computed(() => props.label
            && getBackgroundClass(props.label));

        return {
            unlabelled,
            initial,
            backgroundColor,
        };
    },
});
