










































import { defineComponent } from '@vue/composition-api';
import BitcoinIcon from './icons/BitcoinIcon.vue';
import LegacyAccountUpgradeButton from './LegacyAccountUpgradeButton.vue';

export default defineComponent({
    components: {
        BitcoinIcon,
        LegacyAccountUpgradeButton,
    },
});
