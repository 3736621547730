
















import { defineComponent } from '@vue/composition-api';

export enum LockIconType {
    DEFAULT = 'default',
    MASK = 'mask',
}

const LOCK_PATH = 'M14.2 9.5H7.8c-.442 0-.8.298-.8.667v4.666c0 .368.358.667.8.667h6.4c.442 0 .8-.299.8-.667v-4.666c0-.369-.358-.667-.8-.667Zm-5.45-.25v-1.5a2.25 2.25 0 1 1 4.5 0v1.5';

export default defineComponent({
    props: {
        type: {
            type: String,
            default: LockIconType.DEFAULT,
            validator: (value: any) => Object.values(LockIconType).includes(value),
        },
    },
    setup() {
        return {
            LOCK_PATH,
            LockIconType,
        };
    },
});
