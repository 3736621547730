import { render, staticRenderFns } from "./WorldIcon.vue?vue&type=template&id=6cfe8c80&scoped=true&functional=true&"
var script = {}
import style0 from "./WorldIcon.vue?vue&type=style&index=0&id=6cfe8c80&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "6cfe8c80",
  null
  
)

export default component.exports