

































import { defineComponent, computed, ref } from '@vue/composition-api';
import { Tooltip } from '@nimiq/vue-components';
import { useAddressStore } from '../../stores/Address';
import { usePrestakingStore } from '../../stores/Prestaking';
import { useConfig } from '../../composables/useConfig';

import HeroIcon from '../icons/Prestaking/HeroIcon.vue';

export default defineComponent({
    props: {
        asButton: {
            type: Boolean,
            required: false,
            default: true,
        },
        inversePalette: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup() {
        const { activeAddressInfo } = useAddressStore();
        const { activePrestake } = usePrestakingStore();

        const hasPrestake = computed(() => !!activePrestake.value);

        const { config } = useConfig();

        const now = ref(Date.now());
        setInterval(() => {
            now.value = Date.now();
        }, 1000);
        const visible = computed(() => now.value >= config.prestaking.startDate.getTime()
            && (now.value <= config.prestaking.endDate.getTime() || hasPrestake.value));

        const $tooltip = ref<Tooltip | null>(null);
        // watch([hasPrestake, activeAddressInfo], ([has, _]) => {
        //     if (!has && $tooltip.value && visible.value) {
        //         ($tooltip.value.$el.querySelector('.trigger') as HTMLAnchorElement).focus();
        //     }
        // });

        return {
            activeAddressInfo,
            visible,
            hasPrestake,
            $tooltip,
        };
    },
    components: {
        Tooltip,
        HeroIcon,
    },
});
