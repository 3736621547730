

















import { defineComponent, computed } from '@vue/composition-api';
import { PageBody } from '@nimiq/vue-components';
import Modal from './Modal.vue';
import LegacyAccountNotice from '../LegacyAccountNotice.vue';
import LegacyAccountUpgradeButton from '../LegacyAccountUpgradeButton.vue';
import { useAccountStore, AccountType } from '../../stores/Account';

export default defineComponent({
    setup() {
        const { accountInfos } = useAccountStore();

        const hasMultiAddressAccount = computed(() =>
            Object.values(accountInfos.value).find((accountInfo) => accountInfo.type !== AccountType.LEGACY));

        return {
            hasMultiAddressAccount,
        };
    },
    components: {
        Modal,
        PageBody,
        LegacyAccountNotice,
        LegacyAccountUpgradeButton,
    },
});
