







import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    props: {
        hole: {
            type: Boolean,
            default: false,
        },
    },
});
