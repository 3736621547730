














































































import { defineComponent, computed } from '@vue/composition-api';
import {
    CircleSpinner,
    AlertTriangleIcon,
    Identicon,
    FiatAmount,
    CrossIcon,
} from '@nimiq/vue-components';
import { SwapAsset } from '@nimiq/fastspot-api';
import { Transaction, TransactionState } from '../stores/UsdtTransactions';
import Avatar from './Avatar.vue';
import Amount from './Amount.vue';
import FiatConvertedAmount from './FiatConvertedAmount.vue';
// import HistoricValueIcon from './icons/HistoricValueIcon.vue';
import BitcoinIcon from './icons/BitcoinIcon.vue';
import BankIcon from './icons/BankIcon.vue';
import UsdtIcon from './icons/UsdtIcon.vue';
import SwapSmallIcon from './icons/SwapSmallIcon.vue';
import { CryptoCurrency, FIAT_PRICE_UNAVAILABLE, BANK_ADDRESS } from '../lib/Constants';
import { assetToCurrency } from '../lib/swap/utils/Assets';
import { useUsdtTransactionInfo } from '../composables/useUsdtTransactionInfo';
import { useFormattedDate } from '../composables/useFormattedDate';
import TransactionListOasisPayoutStatus from './TransactionListOasisPayoutStatus.vue';

export default defineComponent({
    props: {
        transaction: {
            type: Object as () => Transaction,
            required: true,
        },
    },
    setup(props) {
        const constants = { FIAT_PRICE_UNAVAILABLE, BANK_ADDRESS };

        const state = computed(() => props.transaction.state);

        const transaction = computed(() => props.transaction);

        const {
            txValue,
            data,
            isCancelledSwap,
            isIncoming,
            peerAddress,
            peerLabel,
            swapData,
            swapInfo,
            fiat,
        } = useUsdtTransactionInfo(transaction);

        const timestamp = computed(() => transaction.value.timestamp && transaction.value.timestamp * 1000);
        const { dateDay, dateMonth, dateTime } = useFormattedDate(timestamp);

        const ticker = CryptoCurrency.USDT;

        return {
            constants,
            state,
            TransactionState,
            dateDay,
            dateMonth,
            dateTime,
            txValue,
            data,
            fiat,
            isIncoming,
            // isSwapProxy,
            peerAddress,
            peerLabel,
            SwapAsset,
            swapInfo,
            swapData,
            isCancelledSwap,
            ticker,
            assetToCurrency,
        };
    },
    components: {
        CircleSpinner,
        CrossIcon,
        AlertTriangleIcon,
        Avatar,
        Amount,
        FiatConvertedAmount,
        Identicon,
        BitcoinIcon,
        BankIcon,
        UsdtIcon,
        SwapSmallIcon,
        TransactionListOasisPayoutStatus,
        FiatAmount,
    },
});
