















import { defineComponent } from '@vue/composition-api';
import { SettlementStatus } from '@nimiq/oasis-api';
import { SwapEurData } from '../stores/Swaps';

export default defineComponent({
    props: {
        data: {
            type: Object as () => SwapEurData,
            required: true,
        },
    },
    setup() {
        return {
            SettlementStatus,
        };
    },
});
