


















import { defineComponent, ref, onMounted, onUnmounted, computed, watch } from '@vue/composition-api';

import CrossCloseButton from './CrossCloseButton.vue';

export default defineComponent({
    name: 'search-bar',
    props: {
        value: {
            type: String,
            default: '',
        },
    },
    setup(props, context) {
        const containerDiv = ref<HTMLDivElement | null>(null);
        const searchBarInput = ref<HTMLInputElement | null>(null);
        const inputWidth = ref(1000);
        const containerWidth = ref(1000);
        const isInputFocused = ref(false);

        const observer = ref<ResizeObserver>();

        const placeholderText = computed(() => {
            if (containerWidth.value < 100) return '';
            if (maxWidth.value === '100%' && (containerWidth.value > 400 || inputWidth.value > 350)) {
                return context.root.$t('Search transactions by contact, address, etc.');
            }
            if (containerWidth.value > 210 || inputWidth.value > 150) return context.root.$t('Search transactions');
            return context.root.$t('Search');
        });

        const maxWidth = computed(() => {
            if (!searchBarInput.value) return '100%';
            return isInputActive.value ? '100%' : 'var(--default-sb-width)';
        });

        const handleFocus = () => {
            isInputFocused.value = true;
        };

        const handleBlur = () => {
            isInputFocused.value = false;
        };

        const inputValue = computed(() => props.value);

        const isInputActive = computed(() => {
            // Only collapse if not focused and no text
            if (!isInputFocused.value && inputValue.value === '') return false;
            return true;
        });

        const handleClose = (e: Event) => {
            context.emit('input', '');
            // Prevent the search bar from losing or gaining focus when not intended
            e.stopImmediatePropagation();
        };

        watch([observer, searchBarInput, containerDiv], () => {
            if (!observer.value) return;
            if (searchBarInput.value) observer.value.observe(searchBarInput.value);
            if (containerDiv.value) observer.value.observe(containerDiv.value);
        });

        onMounted(() => {
            if ('ResizeObserver' in window) {
                observer.value = new ResizeObserver((entries: ResizeObserverEntry[]) => {
                    for (const entry of entries) {
                        if (entry.target === containerDiv.value) {
                            containerWidth.value = entry.contentBoxSize
                                ? ('length' in entry.contentBoxSize
                                    ? entry.contentBoxSize[0].inlineSize
                                    : (entry.contentBoxSize as any).inlineSize)
                                : entry.contentRect.width;
                        } else if (entry.target === searchBarInput.value) {
                            inputWidth.value = entry.contentBoxSize
                                ? ('length' in entry.contentBoxSize
                                    ? entry.contentBoxSize[0].inlineSize
                                    : (entry.contentBoxSize as any).inlineSize)
                                : entry.contentRect.width;
                        }
                    }
                });
            }
        });

        onUnmounted(() => {
            if (observer.value) {
                if (containerDiv.value) observer.value.unobserve(containerDiv.value);
                if (searchBarInput.value) observer.value.unobserve(searchBarInput.value);
            }
        });

        return {
            containerDiv,
            searchBarInput,
            maxWidth,
            placeholderText,
            isInputActive,
            handleFocus,
            handleBlur,
            handleClose,
        };
    },
    components: {
        CrossCloseButton,
    },
});
